<template>
  <v-container>
    <v-row>
      <v-breadcrumbs :items="bread" large></v-breadcrumbs>
    </v-row>
    <v-row>
      <v-col>
        <div v-if="titulo === 'Unidad Gestion'">
          <h1>Unidad de gesti&oacute;n</h1>
        </div>
        <div v-else-if="titulo === 'Configuracion Mensaje Notificacion'">
          <h1>Configuraci&oacute;n Mensaje Notificaci&oacute;n</h1>
        </div>
        <div v-else-if="titulo === 'Configuracion Notificacion'">
          <h1>Configuraci&oacute;n Notificaciones</h1>
        </div>
        <div v-else>
          <h1>{{ titulo }}</h1>
        </div>
        <br />
        <v-btn
          color="primary"
          :to="{ name: 'Nuevo', params: { model: this.$route.params.model } }"
        >
          Agregar Nuevo
        </v-btn>
        <br />
        <Tabla
          v-if="loaded"
          :model="this.$route.params.model"
          :hiddenColumns="[
            'id',
            'imagenURL',
            'password',
            'emailProofToken',
            'emailProofTokenExpiresAt',
            'encryptedPassword',
            'userCreated_id',
            'bases',
            'descripcion',
            'telefono',
            'destino',
            'options',
          ]"
          :datos="data"
          :translatedColumns="[
            { campo: 'createdAt', trad: 'Creado' },
            { campo: 'updatedAt', trad: 'Actualizado' },
            { campo: 'rol_id', trad: 'Rol' },
            { campo: 'emailStatus', trad: 'Correo confirmado' },
            { campo: 'email', trad: 'Correo Electronico' },
            { campo: 'userCreated_id', trad: 'Creado por' },
            { campo: 'lineaCredito_id', trad: 'Operatoria' },
            { campo: 'tipo_parametro_id', trad: 'Parametro' },
            { campo: 'nombre', trad: 'Nombre' },
            { campo: 'vencimiento', trad: 'Vencimiento' },
            { campo: 'fideicomiso_id', trad: 'Unidad de Gestión' },
            { campo: 'sectorProductivo', trad: 'Sector Productivo' },
            { campo: 'tipo_servicio_id', trad: 'Tipo de operatoria' },
            { campo: 'cfgNotificacionMsg_id', trad: 'Mensaje' },
            {
              campo: 'status', // Assuming your data has a 'status' property (boolean)
              trad: 'Activa',
            },
          ]"
        >
        </Tabla>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Tabla from '@/components/Tabla.vue';

import { API_URL } from '@/common/config';
import { formatDateUnix, redireccion } from '../common/util';

const axios = require('axios');

export default {
  data: () => ({
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Listado',
        disabled: true,
        href: '/',
      },
      {
        text: '',
        disabled: true,
        href: '/',
      },
    ],
    loading: false,
    loaded: false,
    data: [],
    headers: [],
  }),
  name: 'Listado',
  components: {
    Tabla,
  },
  computed: {
    titulo() {
      let t = this.$route.params.model;
      if (t === 'servicio') {
        t = 'operatoria';
      }
      if (t === 'tipoServicios') {
        t = 'tipoOperatorias';
      }
      if (t === 'servicioParametros') {
        t = 'operatoriaParametros';
      }
      if (t === 'cfgNotificacion') {
        t = 'configuracionNotificaciones';
      }
      if (t === 'cfgNotificacionMsg') {
        t = 'configuracionMensajeNotificacion';
      }
      t = t.charAt(0).toUpperCase() + t.slice(1);
      return t.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
    },
  },
  methods: {
    formatDates(items) {
      try {
        return items.map((item) => ({
          ...item,
          createdAt: item.createdAt
            ? item.createdAt
              ? formatDateUnix(item.createdAt)
              : '' // Asigna cadena vacía si no es válido
            : '',
          updatedAt: parseInt(item.updatedAt)
            ? item.updatedAt
              ? formatDateUnix(item.updatedAt)
              : '' // Asigna cadena vacía si no es válido
            : '',
        }));
      } catch (error) {
        console.error('Error formateando fechas:', error);
      }
    },
    async getData() {
      this.loading = true;
      let url = '';
      if (this.$route.params.model === 'unidadGestion') {
        url = `${API_URL}api/admin/${this.$route.params.model}/`;
      } else if (this.$route.params.model === 'documentos') {
        url = `${API_URL}api/documentoMaestros/`;
      } else {
        url = `${API_URL}api/${this.$route.params.model}/`;
      }
      await axios
        .get(url, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          if (response.data.status === 'success') {
            let dataToSet = [];

            if (this.$route.params.model === 'documentos') {
              dataToSet = response.data.documentacionMaestros;
            } else {
              if (
                this.$route.params.model !== 'unidadGestion' ||
                this.$route.params.model !== 'unidadGestion'
              ) {
                dataToSet = response.data[this.$route.params.model];
              }
              if (this.$route.params.model === 'servicio') {
                dataToSet = response.data.lineaCreditos;
              }
              if (this.$route.params.model === 'unidadGestion') {
                dataToSet = response.data.fideicomisos;
              }
              if (this.$route.params.model === 'tipoServicios') {
                dataToSet = response.data.tipoServicios;
              }
              if (this.$route.params.model === 'tipoParametros') {
                dataToSet = response.data.tipoParametros;
              }
              if (this.$route.params.model === 'servicioParametros') {
                dataToSet = response.data.servicioParametros;
              }
              if (this.$route.params.model === 'solicitudEstado') {
                dataToSet = response.data.solicitudEstado;
              }
            }

            this.data = this.formatDates(dataToSet);

            console.log('Set loaded and loading');
            this.loaded = true;
            this.loading = false;
          }
        })
        .catch((e) => {
          console.log(e);
          const { response } = e;
          if (response.status === 404) {
            this.$router.push('/');
          }
        });
    },
  },
  async created() {
    const isValid = await redireccion();
    if (!isValid) {
      localStorage.removeItem('token');
      localStorage.clear();
      this.$router.push('/login');
    }
    this.bread[2].text = this.titulo;
    this.getData();
  },
};
</script>
